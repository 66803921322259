<template>

</template>

<script>
  import * as util from "@/util"
  export default {
    props : ['theme'],
    data: () => ({

    }),
    methods: {

    },
    async created() {
      this.$store.commit('deleteAll');
      var res = await this.$store.dispatch('crud_new', {url: "login_receiver_notoken", data: {func: "login_before", code: this.code}});
      if (res.code == 0) {
        this.$store.commit('initializeStore');//vuexをリセット
        this.$store.commit('storeObject', {dest: 'Admin_company', object: res.data});//certifiedかどうかの判断材料にもなりうる
        this.$store.commit('storeLoginConfirm');//beforeを通ったかどうかのflagを1にする
        this.$router.push({name: 'login_certified', params: {code: this.code}});
      } else {
        this.$router.push({name: 'illegal'});
      }
    },
    computed: {
      code() {
        return this.$route.params.code;
      },
    },
  }

</script>

<style lang="scss">

</style>
